exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-encrypted-photo-app-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/blog/encrypted-photo-app.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-encrypted-photo-app-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-fullstack-serverless-template-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/blog/fullstack-serverless-template.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-fullstack-serverless-template-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-svg-animation-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/blog/svg-animation.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-svg-animation-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-page-projects-aeon-pocket-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/page/projects/aeon-pocket.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-page-projects-aeon-pocket-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-page-projects-apollo-graphql-typescript-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/page/projects/apollo-graphql-typescript.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-page-projects-apollo-graphql-typescript-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-page-projects-aurora-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/page/projects/aurora.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-page-projects-aurora-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-page-projects-ente-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/page/projects/ente.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-page-projects-ente-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-page-projects-paper-dropdown-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/page/projects/paper-dropdown.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-page-projects-paper-dropdown-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-page-projects-sparkx-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/opt/build/repo/content/page/projects/sparkx.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-page-projects-sparkx-mdx" */),
  "component---src-templates-page-js-content-file-path-content-page-about-me-mdx": () => import("./../../../src/templates/Page.js?__contentFilePath=/opt/build/repo/content/page/about-me.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-content-page-about-me-mdx" */),
  "component---src-templates-page-js-content-file-path-content-page-projects-mdx": () => import("./../../../src/templates/Page.js?__contentFilePath=/opt/build/repo/content/page/projects.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-content-page-projects-mdx" */),
  "component---src-templates-page-js-content-file-path-content-page-work-ex-mdx": () => import("./../../../src/templates/Page.js?__contentFilePath=/opt/build/repo/content/page/work-ex.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-content-page-work-ex-mdx" */)
}

